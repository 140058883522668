import React from 'react';
import CampaignFieldsSelection from './campaignFieldsSelection';
import CampaignSelection from './campaignSelection';

const SaleForceCampaignSettings = ({ showSaleForceModal, setShowSaleForceModal, podcastId, salesforceCampaignId,refetchPodcast }) => {
    const onClose = () => {
        setShowSaleForceModal('');
    };

    return (
        <>
            {showSaleForceModal === 'CampaignSelection' ? (
                <CampaignSelection
                    podcastId={podcastId}
                    onClose={onClose}
                    setShowSaleForceModal={setShowSaleForceModal}
                    salesforceCampaignId = {salesforceCampaignId}
                    refetchPodcast= {refetchPodcast}
                />
            ) : (
                <CampaignFieldsSelection onClose={onClose} />
            )}
        </>
    );
};

export default SaleForceCampaignSettings;
