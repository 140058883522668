import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

import { dispatch } from 'store/store';
import {
	setEpisodeUpdates,
	setEpisodeResourceCreates,
	setEpisodeResourceUpdates,
	setEpisodeResourceDeletes,
} from 'store/reducers/data/episode';

import { Collapse } from 'react-bootstrap';

import Button from 'components/shared/button';
import CastedModal from 'components/shared/modal';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import { PODCAST_COLLECTION_TYPE, getContentType } from 'components/constants';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

import Toast from 'services/toast';

const EpisodeEdit = (props) => {
	// Props
	const {
		episodeId,
		accountId,
		episode,
		podcast,
		permissions,
		episodeUpdates,
		episodeResourceCreates,
		episodeResourceUpdates,
		episodeResourceDeletes,
		episodeUploads,
		userId,
	} = props;

	const { slug: episodeSlug } = episode;
	const { slug: podcastSlug, customDomain } = podcast;
	const episodeContentType = getContentType(podcast.collectionType);

	const episodePermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.EPISODES, podcast.id)
				.rolePermission
		: {};

	/** Mutations **/
	const [updateEpisode] = useMutation(mutations.updateEpisodeById, {
		refetchQueries: ['EpisodeById'],
	});

	const [createRelatedResource] = useMutation(
		mutations.createRelatedResource,
		{
			refetchQueries: ['getEpisodeRelatedResources'],
		}
	);

	const [updateRelatedResource] = useMutation(
		mutations.updateRelatedResource,
		{
			refetchQueries: ['getEpisodeRelatedResources'],
		}
	);

	const [deleteRelatedResource] = useMutation(
		mutations.deleteRelatedResource,
		{
			refetchQueries: ['getEpisodeRelatedResources'],
		}
	);

	const [updatePodcast] = useMutation(mutations.updatePodcastById, {
		refetchQueries: ['GetPodcastById'],
	});

	const [publishMedia] = useMutation(mutations.publishEpisodeMedia);

	const [processRssAudioFile] = useMutation(mutations.processRssAudioFile);

	// State
	const [isLoading, setIsLoading] = useState(false);
	const [showUndoModal, setShowUndoModal] = useState(false);

	const clearAllChanges = () => {
		dispatch(setEpisodeUpdates({}));
		dispatch(setEpisodeResourceCreates([]));
		dispatch(setEpisodeResourceUpdates([]));
		dispatch(setEpisodeResourceDeletes([]));
	};

	const saveChanges = async () => {
		setIsLoading(true);

		let { youtubeDescription } = episodeUpdates;
		youtubeDescription = youtubeDescription || '';

		if ((!episode.name && !episodeUpdates.name) || (youtubeDescription.length > 5000)) {
			Toast.error('Ensure all required fields are complete and valid.', "" ,{toastId: 'episode-edit-error'});
			setIsLoading(false);
			return;
		}

		if (Object.keys(episodeUpdates).length > 0) {
			await updateEpisode({
				variables: {
					accountId: parseInt(accountId, 10),
					episodeId,
					episode: episodeUpdates,
				},
			});

			// If an episode name is updated AND the episode is part of a one-off collection we
			// also need to update that collections name
			if (
				podcast.collectionType === PODCAST_COLLECTION_TYPE.one_off &&
				episodeUpdates.name
			) {
				await updatePodcast({
					variables: {
						podcastId: podcast.id,
						podcast: {
							name: episodeUpdates.name,
							accountId: parseInt(accountId, 10),
						},
					},
				});
			}

			if (episodeUpdates.storageLink) {
				// Can remove fileName in a future release - left here for backwards compat in January release
				await publishMedia({
					variables: {
						episodeId,
						accountId: parseInt(accountId, 10),
					},
				});
			}

			if (episodeUpdates.rssAudioStorageLink) {
				await processRssAudioFile({
					variables: {
						episodeId,
						accountId: parseInt(accountId, 10),
					},
				});
			}

			trackEvent('item-saved', {
				itemName: episode.name,
				collectionName: podcast.name,
				itemType:
					podcast.collectionType === PODCAST_COLLECTION_TYPE.one_off
						? 'single'
						: 'episode',
				updates: episodeUpdates,
			});

			dispatch(setEpisodeUpdates({}));
		}

		for (let i = 0; i < episodeResourceCreates.length; i++) {
			const resource = episodeResourceCreates[i];
			await createRelatedResource({
				variables: {
					episodeId,
					resource: {
						name: resource.name,
						url: resource.url,
						thumbnail: resource.thumbnail,
						order: resource.order,
					},
				},
			});

			trackEvent('new-resource', {
				itemName: episode.name,
				collectionName: podcast.name,
				resourceName: resource.name,
			});
		}

		dispatch(setEpisodeResourceCreates([]));

		for (let i = 0; i < episodeResourceUpdates.length; i++) {
			const resource = episodeResourceUpdates[i];

			await updateRelatedResource({
				variables: {
					resource: {
						id: resource.id,
						name: resource.name,
						url: resource.url,
						thumbnail: resource.thumbnail,
						order: resource.order,
					},
				},
			});
		}

		dispatch(setEpisodeResourceUpdates([]));

		for (let i = 0; i < episodeResourceDeletes.length; i++) {
			const resource = episodeResourceDeletes[i];

			await deleteRelatedResource({
				variables: {
					resourceId: resource.id,
				},
			});
		}

		dispatch(setEpisodeResourceDeletes([]));

		setIsLoading(false);
	};

	// Auto-save episode when episode or rss file has been uploaded
	useEffect(() => {
		if (
			episodeUploads.length === 0 &&
			(
				(episodeUpdates.storageLink &&
				episodeUpdates.storageLink !== 'uploading') ||
				(episodeUpdates.rssAudioStorageLink &&
				episodeUpdates.rssAudioStorageLink !== 'uploading')
			)
		) {
			saveChanges();
		}
	}, [episodeUpdates.storageLink, episodeUpdates.rssAudioStorageLink, episodeUploads.length]);

	// Sharing urls
	const publicUrl = customDomain
		? `https://${customDomain}`
		: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;
	const viewUrl = `${publicUrl}/api/preview?secret=${episodeId}&slug=/public/${accountId}/${podcastSlug}/${episodeSlug}`;

	const discardModalDialogOptions = [
		{
			variant: 'cancel',
			text: 'Cancel',
			onClick: () => {
				setShowUndoModal(false);
			},
		},
		{
			variant: 'success',
			text: 'Discard Changes',
			onClick: () => {
				setShowUndoModal(false);
				clearAllChanges();
			},
		},
	];

	/**
	 * Render
	 */
	return (
		<Collapse
			in={
				Object.keys(episodeUpdates).length > 0 ||
				episodeResourceCreates.length > 0 ||
				episodeResourceUpdates.length > 0 ||
				episodeResourceDeletes.length > 0 
			}
		>
			<div
				id="edit-detail--collapse"
				style={{ position: 'absolute', zIndex: 1, width: '100%' }}
			>
				<Container fluid>
					<Row className="episode-detail--edit">
						<Col className="p-0">
							<h4 className="episode-header--name">
								{`${
									episode.storageLink ? 'Edit' : 'Add'
								} ${episodeContentType} Info`}
							</h4>
						</Col>
						<Col className="p-0 d-flex justify-content-end align-items-center">
							<div className="episode-header--view" xs="auto">
								<a
									className="ext-link light"
									target="_blank"
									href={viewUrl}
								>
									Preview Page
								</a>
							</div>
							<div className="episode-edit--undo" xs="auto">
								<Button
									variant="light"
									className="episode-header--button"
									onClick={() => {
										setShowUndoModal(true);
									}}
								>
									Discard Changes
								</Button>
							</div>
							{isLoading ? (
								<Button
									variant="light"
									className="episode-header--button"
								>
									<Spinner animation="border" size="sm" />
								</Button>
							) : (
								<div className="episode-edit--save" xs="auto">
									<Button
										variant="success"
										className="episode-header--button"
										onClick={async () => {
											await saveChanges();
										}}
										disabled={
											!episodePermission.canEdit ||
											episodeUploads.length > 0
										}
									>
										Save Changes
									</Button>
								</div>
							)}
						</Col>
					</Row>
				</Container>
				<CastedModal
					show={showUndoModal}
					handleClose={() => {
						setShowUndoModal(false);
					}}
					title="Discard Changes"
					dialogOptions={discardModalDialogOptions}
				>
					<p>
						Are you sure you want to undo the changes you've made?
					</p>
				</CastedModal>
			</div>
		</Collapse>
	);
};

const mapStateToProps = (state) => ({
	episodeUpdates: state.episode.episodeUpdates,
	episodeResourceCreates: state.episode.episodeResourceCreates,
	episodeResourceUpdates: state.episode.episodeResourceUpdates,
	episodeResourceDeletes: state.episode.episodeResourceDeletes,
	episodeUploads: state.episode.episodeUploads,
	permissions: state.auth.permissions,
	userId: state.auth.profile.id,
});

export default connect(mapStateToProps)(EpisodeEdit);
