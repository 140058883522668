import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import * as Sentry from '@sentry/browser';

// Internal libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import Toast from 'services/toast';

// External Components
import { Spinner } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';

// Internal Components
import Button from 'components/shared/button';
import ConfirmationModal from 'components/shared/confirmationModal';

// Redux
import { dispatch } from 'store/store';
import {
	hideDisconnectAccountIntegrationModal,
	showDisconnectAccountIntegrationModal,
} from 'store/reducers/ui/modals/disconnectAccountIntegrationModal';

// Internal Assets
import wordpressLogo from 'assets/logos/wordpress-logotype-standard.png';
import salesforceLogo from 'assets/logos/salesforce-logo.png';

import { ACCOUNT_INTEGRATION_TYPES } from 'components/constants';
import SaleForceCampaignSettings from 'components/shows/settings/salesForceCampaignSettings';

const AccountIntegrations = (props) => {
	const {
		history,
		modals,
		permissions,
		selectedAccountId: accountId,
	} = props;

		const [showSaleForceModal, setShowSaleForceModal] = useState('');
	
	const {
		data: apiKeyData,
		loading: apiKeyLoading,
		error: apiKeyError,
	} = useQuery(queries.apiKeys, {
		variables: { accountId },
	});

	const [revokeApiKey] = useMutation(mutations.revokeApiKey, {
		refetchQueries: ['apiKeys'],
	});

	const [revokeSalesforceToken] = useMutation(mutations.revokeSalesforceToken, {
		refetchQueries: ['integrationStatus'],
		variables: { accountId },
		onCompleted: () => {
			trackEvent('revoke-salesforce-integration', { integrationName: 'salesforce' });
			Toast.success('Salesforce Integration Removed!');
		},
		onError: (err) => {
			Toast.error('Unable to remove integration with Salesforce');
			Sentry.captureException(err);
		},
	});

	const {
		data: integrationStatus,
		loading: integrationLoading,
		error: integrationError,
		refetch
	} = useQuery(queries.getSaleforceIntegrationStatus, {
		variables: { accountId },
	});


	useEffect(() => {
    const handleRefreshSalesForce = (event) => {
      if (event.key === "refreshSalesForceAuth") {
				refetch()
				setShowSaleForceModal('FieldsSelection')
      }
    };
    window.addEventListener("storage", handleRefreshSalesForce);
    return () => {
      window.removeEventListener("storage", handleRefreshSalesForce);
    };
  }, []);

	const revokeSaleForce = async () => {
		try {
			await revokeSalesforceToken();
			await refetch();
			localStorage.setItem("refreshSalesForceAuth", false);

		} catch (error) {
			console.log(error)
		}
	}

	const getSaleforceIntegrationStatus = integrationStatus?.['getSaleforceIntegrationStatus'];

	const { apiKeys = [] } = apiKeyData || {};

	const accountLevelKeys = apiKeys.filter((apiKey) => apiKey.isAccountLevel);

	const wordpressIntegrations =
		accountLevelKeys.filter(
			(accountLevelKey) =>
				accountLevelKey.integrationName ===
				ACCOUNT_INTEGRATION_TYPES.WORDPRESS
		) || [];

	const disconnectIntegration = async () => {
		try {
			const res = await revokeApiKey({
				variables: {
					id: modals.disconnectAccountIntegrationModal
						.integrationToDisconnect?.id,
				},
			});

			const { data } = res;
			const { revokeApiKey: keyRevoked } = data;

			if (keyRevoked) {
				Toast.success('Integration disconnected');
				trackEvent('delete-api-key', {
					keyName:
						modals.disconnectAccountIntegrationModal
							.integrationToDisconnect?.name,
				});
			}
		} catch (e) {
			console.error(e);
			Toast.error('Unable to disconnect integration');
		} finally {
			dispatch(hideDisconnectAccountIntegrationModal());
		}
	};

	const handleSalesforceCTA = () => {
		if(getSaleforceIntegrationStatus){
			setShowSaleForceModal('FieldsSelection')
		}else{
			window.open(`https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9XgkMlifdwVCMdh.w4TTncMTAk9RUKCPJ4N6UgFnecwRbsaFjHYw6iAIoR.s0NqucvcHcKfcDxA10d4lY&redirect_uri=${process.env.REACT_APP_SALESFORCE_CALLBACK_URL}&scope=api%20refresh_token%20offline_access`,"_blank")
		}
	}


	return (
		<div className="account-integrations-page">
			<div className="account-integrations-header-row">
				<h2
					className="account-integrations-header"
					onClick={() => history.goBack()}
				>
					<FaChevronLeft className="arrow-left" /> Account
					Integrations
				</h2>
			</div>
			<div className="account-integrations-content">
				<div className="integration wordpress-integration">
					<div className="description">
						<img src={wordpressLogo} />
						<p>
							Linking with WordPress allows Casted to provide you
							with insights and user engagement analytics
							surrounding your blogs and other written content on
							your platform.
						</p>
					</div>
					<div className="actions">
						<div className="actions-help">
							<h5>WordPress Integration</h5>
							<p>
								Connect your Casted account to your WordPress
								site
							</p>
							<div>
								<Button
									href={
										'https://help.casted.us/en/wordpress-integration'
									}
								>
									Connect With WordPress
								</Button>
							</div>
						</div>
						{wordpressIntegrations.length > 0 && (
							<div className="active-integrations">
								{wordpressIntegrations.map(
									(accountLevelKey) => {
										const index =
											accountLevelKey.name.indexOf(
												'wp-admin'
											);
										if (index !== -1) {
											return (
												<div className="active-integration">
													<div className="integration-name">
														<h5>Site:</h5>{' '}
														{accountLevelKey.name}
													</div>
													<div>
														<Button
															variant="danger"
															onClick={() => {
																dispatch(
																	showDisconnectAccountIntegrationModal(
																		accountLevelKey
																	)
																);
															}}
														>
															Disconnect with
															WordPress
														</Button>
													</div>
												</div>
											);
										}
									}
								)}
							</div>
						)}
						
					</div>
				</div>
				<div className="integration wordpress-integration">
					<div className="description">
						<img height={70} src={salesforceLogo} />
						<p>
						Link your Salesforce account with Casted to sync listening behavior to Leads, Contacts and Accounts.
						</p>
						<a href='https://casted.atlassian.net/wiki/spaces/CA/pages/2216034307/Setting+up+Salesforce+Integration' target='blank'>Click here for more details and instructions.</a>
					</div>
					<div className="actions">
						<div className="actions-help">
							<h5>Salesforce Integration</h5>
							<p>
								Connect your Casted account to your Salesforce
								dashboard
							</p>
							<div>
								<Button
									variant={getSaleforceIntegrationStatus?'primary':'info'}
									className= {'saleforce-button'}
									onClick={handleSalesforceCTA}
								>
									{getSaleforceIntegrationStatus ? 'View Field Mappings': 'Connect With Salesforce'}
								</Button>
								{getSaleforceIntegrationStatus &&	<Button
									variant='danger'
									onClick = {revokeSaleForce}
								>
									Disconnect
								</Button>}
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<ConfirmationModal
				isOpen={modals.disconnectAccountIntegrationModal.showModal}
				title="Disconnect Integration"
				confirmationText={`Are you sure you want to disconnect the integration named "${
					modals.disconnectAccountIntegrationModal
						.integrationToDisconnect?.name || ''
				}"?`}
				confirmationButtonLabel="Disconnect"
				onCancel={() => {
					dispatch(hideDisconnectAccountIntegrationModal());
				}}
				onConfirm={disconnectIntegration}
			/>
					{showSaleForceModal && <SaleForceCampaignSettings
						setShowSaleForceModal = {setShowSaleForceModal}
						showSaleForceModal = {showSaleForceModal}
				/>}
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions.accountRole,
	modals: state.ui.modals,
});

export const AccountIntegrationsWithRouter = withRouter(
	connect(mapStateToProps)(AccountIntegrations)
);
