import { gql } from '@apollo/client';

import Fragments from 'services/graphql/fragments';

export default {
	myAccounts: gql`
		query MyAccounts {
			myAccounts {
				__typename
				...AccountParts
			}
		}
		${Fragments.AccountParts}
	`,
	accountsByDomain: gql`
		query GetAccountsByDomain($domain: String) {
			accounts(domain: $domain) {
				__typename
				...AccountParts
			}
		}
		${Fragments.AccountParts}
	`,
	myAccountRole: gql`
		query MyAccountRole($accountId: Int!) {
			myAccountRole(accountId: $accountId) {
				__typename
				...PermissionObjectParts
			}
		}
		${Fragments.PermissionObjectParts}
	`,
	userRoles: gql`
		query userRoles($accountId: Int!) {
			userRoles(accountId: $accountId) {
				__typename
				...UserRoleParts
			}
		}
		${Fragments.UserRoleParts}
	`,
	userRole: gql`
		query userRole($accountId: Int!, $userId: Int!) {
			userRole(accountId: $accountId, userId: $userId) {
				__typename
				...UserRoleParts
			}
		}
		${Fragments.UserRoleParts}
	`,
	apiKeys: gql`
		query apiKeys($accountId: Int!) {
			apiKeys(accountId: $accountId) {
				__typename
				...ApiKeyParts
			}
		}
		${Fragments.ApiKeyParts}
	`,
	podcastsByAccountId: gql`
		query GetPodcastByAccountId(
			$accountId: Int!
			$includeEpisodes: Boolean
			$episodeLimit: Int
			$userPermissions: Int
		) {
			podcasts(
				accountId: $accountId
				includeEpisodes: $includeEpisodes
				episodeLimit: $episodeLimit
				userPermissions: $userPermissions
			) {
				__typename
				...PodcastParts
			}
		}
		${Fragments.PodcastParts}
	`,
	podcastById: gql`
		query GetPodcastById($podcastId: UUID!) {
			podcast(podcastId: $podcastId) {
				__typename
				episodeCount
				...PodcastParts
			}
		}
		${Fragments.PodcastParts}
	`,
	getPodcastSlug: gql`
		query GetPodcastSlug($podcastId: UUID!) {
			podcast(podcastId: $podcastId) {
				slug
			}
		}
	`,
	episodesByPodcastId: gql`
		query Episodes(
			$podcastId: UUID!
			$sortField: String
			$sortOrder: String
			$offset: Int
			$limit: Int
			$publicStatus: PublicStatus
		) {
			episodes(
				podcastId: $podcastId
				sortField: $sortField
				sortOrder: $sortOrder
				offset: $offset
				limit: $limit
				publicStatus: $publicStatus
			) {
				__typename
				...EpisodeParts
			}
		}
		${Fragments.EpisodeParts}
	`,
	episodeById: gql`
		query EpisodeById(
			$podcastId: UUID!
			$episodeId: UUID!
			$includeThemeMatches: Boolean
		) {
			episode(
				podcastId: $podcastId
				episodeId: $episodeId
				includeThemeMatches: $includeThemeMatches
			) {
				__typename
				...EpisodeParts
				podcast {
					__typename
					...PodcastParts
				}
				episodeThemeMatches {
					id
					episodeId
					themeId
					documentId
					status
					theme {
						id
						name
						color
					}
				}
			}
		}
		${Fragments.EpisodeParts}
		${Fragments.PodcastParts}
	`,
	getEpisodeStatus: gql`
		query getEpisodeStatus($episodeId: UUID!) {
			basicEpisode(episodeId: $episodeId) {
				id
				status
				publicStatus
			}
		}
	`,
	// can add additional columns to below call as needed
	getEpisodeBasic: gql`
		query getEpisodeBasic($episodeId: UUID!, $includePodcast: Boolean) {
			basicEpisode(episodeId: $episodeId, includePodcast: $includePodcast) {
				id
				duration
				podcastId
				slug
				podcast {
					slug
				}
			}
		}
	`,
	clipsByEpisodeId: gql`
		query ClipsInEpisode($episodeId: UUID!) {
			clipsInEpisode(episodeId: $episodeId) {
				__typename
				...ClipParts
				episode {
					__typename
					id
					name
					season
					episode
					storageLink
					duration
				}
			}
		}
		${Fragments.ClipParts}
	`,
	clipById: gql`
		query clipById($clipId: UUID!) {
			clip(clipId: $clipId) {
				__typename
				...ClipParts
			}
		}
		${Fragments.ClipParts}
	`,
	getSignedUrls: gql`
		query GetSignedUrls(
			$accountId: Int!
			$filename: String!
			$contentType: String!
		) {
			signedUrls(
				accountId: $accountId
				filename: $filename
				contentType: $contentType
			)
		}
	`,
	getTranscriptSignedUrls: gql`
		query GetTranscriptSignedUrls(
			$accountId: Int!
			$filename: String!
			$contentType: String!
		) {
			transcriptSignedUrls(
				accountId: $accountId
				filename: $filename
				contentType: $contentType
			)
		}
	`,
	getTranscript: gql`
		query GetTranscript($accountId: Int!, $episodeId: UUID!, $format: String!) {
			getTranscript(
				accountId: $accountId
				episodeId: $episodeId
				format: $format
			)
		}
	`,
	getTranscriptProgress: gql`
		query GetTranscriptProgress($accountId: Int!, $episodeId: UUID!) {
			getTranscriptProgress(accountId: $accountId, episodeId: $episodeId) {
				__typename
				...JobParts
			}
		}
		${Fragments.JobParts}
	`,
	getAccountBrandSettings: gql`
		query GetAccountBrandSettings($accountId: Int!) {
			getAccountBrandSettings(accountId: $accountId)
		}
	`,
	getEpisodeRelatedResources: gql`
		query getEpisodeRelatedResources($episodeId: UUID!) {
			getEpisodeRelatedResources(episodeId: $episodeId) {
				__typename
				...ResourceParts
			}
		}
		${Fragments.ResourceParts}
	`,
	getRelatedResource: gql`
		query GetRelatedResource($resourceId: UUID!) {
			getRelatedResource(resourceId: $resourceId) {
				__typename
				...ResourceParts
			}
		}
		${Fragments.ResourceParts}
	`,
	checkPublishSchedule: gql`
		query checkPublishSchedule($episodeId: UUID!) {
			checkPublishSchedule(episodeId: $episodeId) {
				__typename
				...GraphileJobParts
			}
		}
		${Fragments.GraphileJobParts}
	`,
	checkGenerateClipSuggestions: gql`
		query checkGenerateClipSuggestions($episodeId: UUID!) {
			checkGenerateClipSuggestions(episodeId: $episodeId) {
				__typename
				...GraphileJobParts
			}
		}
		${Fragments.GraphileJobParts}
	`,
	getShowCategories: gql`
		query showCategories {
			showCategories {
				__typename
				...ShowCategoryParts
			}
		}
		${Fragments.ShowCategoryParts}
	`,
	// can add additional columns to below call as needed
	getAccountInfo: gql`
		query getAccountInfo($accountId: Int!) {
			account(accountId: $accountId) {
				__typename
				transcriptionCredits
			}
		}
	`,
	getEpisodeListenerSources: gql`
		query getSourceTotal(
			$episodeId: UUID!
			$podcastId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getSourceTotal(
				episodeId: $episodeId
				podcastId: $podcastId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getAudiogramTemplates: gql`
		query audiogramTemplates($accountId: Int!, $includeVideo: Boolean) {
			audiogramTemplates(accountId: $accountId, includeVideo: $includeVideo) {
				...AudiogramTemplateParts
			}
		}
		${Fragments.AudiogramTemplateParts}
	`,
	getAudiogramSignedUrl: gql`
		query getAudiogramSignedUrl($filename: String!) {
			getAudiogramSignedUrl(filename: $filename)
		}
	`,
	podcastIntegrations: gql`
		query podcastIntegrations($podcastId: UUID!, $integrationName: String) {
			podcastIntegrations(
				podcastId: $podcastId
				integrationName: $integrationName
			) {
				...IntegrationParts
			}
		}
		${Fragments.IntegrationParts}
	`,
	accountIntegrations: gql`
		query accountIntegrations($accountId: Int!) {
			accountIntegrations(accountId: $accountId) {
				...IntegrationParts
			}
		}
		${Fragments.IntegrationParts}
	`,
	accountUsers: gql`
		query accountUsers(
			$accountId: Int!
			$podcastId: UUID
			$sortField: String
			$sortOrder: String
			$offset: Int
			$limit: Int
			$includeInternalUsers: Boolean
			$includeCollectionPermissions: Boolean
		) {
			accountUsers(
				accountId: $accountId
				sortField: $sortField
				sortOrder: $sortOrder
				offset: $offset
				limit: $limit
				includeInternalUsers: $includeInternalUsers
				includeCollectionPermissions: $includeCollectionPermissions
				podcastId: $podcastId
			) {
				...UserParts
			}
		}
		${Fragments.UserParts}
	`,
	getLanguages: gql`
		query languages {
			languages {
				__typename
				...LanguageParts
			}
		}
		${Fragments.LanguageParts}
	`,
	getAccountSaml: gql`
		query getAccountSaml($accountId: Int!) {
			getAccountSaml(accountId: $accountId) {
				__typename
				...SamlParts
			}
		}
		${Fragments.SamlParts}
	`,
	podcastCount: gql`
		query podcastCount($accountId: Int!) {
			podcastCount(accountId: $accountId)
		}
	`,
	episodeCount: gql`
		query episodeCount($accountId: Int!) {
			episodeCount(accountId: $accountId)
		}
	`,
	totalEpisodeDuration: gql`
		query totalEpisodeDuration($accountId: Int!) {
			totalEpisodeDuration(accountId: $accountId)
		}
	`,

	/**
	 * Metric Queries
	 */

	/* Shared Metric Queries */
	getKnownContactCount: gql`
		query getKnownContactCount(
			$accountId: Int
			$podcastId: UUID
			$episodeId: UUID
			$themeId: UUID
			$startDate: Date!
			$endDate: Date!
		) {
			getKnownContactCount(
				accountId: $accountId
				podcastId: $podcastId
				episodeId: $episodeId
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
			) {
				total_count
				known_count
				unknown_count
			}
		}
	`,

	getTopCompanies: gql`
		query getTopCompanies(
			$accountId: Int
			$podcastId: UUID
			$episodeId: UUID
			$themeId: UUID
			$startDate: Date!
			$endDate: Date!
			$numberOfRows: Int
		) {
			getTopCompanies(
				accountId: $accountId
				podcastId: $podcastId
				episodeId: $episodeId
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
				numberOfRows: $numberOfRows
			) {
				integration_company
				total_impressions
				unique_contacts
				total_listens
				last_listen_date
				_id
			}
		}
	`,

	// Get the top listen locations of an RSS feed (apple, spotify, etc)
	getListenLocations: gql`
		query getListenLocations(
			$accountId: Int
			$podcastId: UUID
			$episodeId: UUID
			$startDate: Date
			$endDate: Date
		) {
			getListenLocations(
				accountId: $accountId
				podcastId: $podcastId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	// Get Traffic Referrals
	getTrafficReferrals: gql`
		query getTrafficReferrals(
			$accountId: Int
			$podcastId: UUID
			$episodeId: UUID
			$clipId: UUID
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getTrafficReferrals(
				accountId: $accountId
				podcastId: $podcastId
				episodeId: $episodeId
				clipId: $clipId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				linkedin
				twitter
				tracking
				direct
				facebook
				total
			}
		}
	`,

	/* Account Level Metrics */
	// Get listen summary over time range for all shows in an account
	getAccountListenSummary: gql`
		query getAccountListenSummary(
			$accountId: Int!
			$startDate: Date
			$endDate: Date
		) {
			getAccountListenSummary(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				play_count
				download_count
				average_listen_time
				total_unique_views
				total_complete_views
				number_episodes
			}
		}
	`,
	// Get listener breakdown for a show over a time range
	getAccountNewAndLoyalListeners: gql`
		query getAccountNewAndLoyalListeners(
			$accountId: Int!
			$startDate: Date
			$endDate: Date
		) {
			getAccountNewAndLoyalListeners(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
			) {
				loyal_listeners
				new_listeners
				episodes_per_listener
			}
		}
	`,
	// Avg Completion % for show
	getAccountAverageCompletion: gql`
		query getAccountAverageCompletion(
			$accountId: Int!
			$startDate: Date!
			$endDate: Date!
		) {
			getAccountAverageCompletion(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	// Get RSS metrics for an account by day - returns an array
	getDailyAccountDownloadAndPlayCount: gql`
		query getDailyAccountDownloadAndPlayCount(
			$accountId: Int!
			$startDate: Date
			$endDate: Date
		) {
			getDailyAccountDownloadAndPlayCount(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
			) {
				play_count
				download_count
				visit_count
				is_video
				date
				_id
			}
		}
	`,
	// Get (Casted website) listen metrics for an account by day - returns an array
	getDailyAccountListens: gql`
		query getDailyAccountListens(
			$accountId: Int!
			$startDate: Date
			$endDate: Date
		) {
			getDailyAccountListens(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				average_listen_time
				total_unique_views
				total_complete_views
				number_episodes
				account_id
				date
				_id
			}
		}
	`,
	// Get the top performing shows by listens for an account - returns an array
	getTopShows: gql`
		query getTopShows(
			$accountId: Int!
			$startDate: Date
			$endDate: Date
			$numberOfRows: Int
			$isHosted: Boolean
		) {
			getTopShows(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
				numberOfRows: $numberOfRows
				isHosted: $isHosted
			) {
				id
				podcast_name
				total_listens
				total_impressions
				total_likes
			}
		}
	`,
	// Get the top performing episodes by listens for a show or account - returns an array
	getTopEpisodes: gql`
		query getTopEpisodes(
			$accountId: Int
			$podcastId: UUID
			$themeId: UUID
			$startDate: Date
			$endDate: Date
			$numberOfRows: Int
			$isHosted: Boolean
		) {
			getTopEpisodes(
				accountId: $accountId
				podcastId: $podcastId
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
				numberOfRows: $numberOfRows
				isHosted: $isHosted
			) {
				id
				name
				podcast_name
				podcast_id
				total_listens
				total_impressions
				published_at
				total_likes
				first_7_listens
				first_30_listens
			}
		}
	`,
	// Get the top clips of a show or episode - returns an array
	getTopClips: gql`
		query getTopClips(
			$podcastId: UUID
			$episodeId: UUID
			$startDate: Date!
			$endDate: Date!
			$numberOfRows: Int
		) {
			getTopClips(
				podcastId: $podcastId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
				numberOfRows: $numberOfRows
			) {
				id
				clip_id
				clip_name
				episode_name
				clip_type
				total_listens
				total_likes
				total_shares
				episode_id
			}
		}
	`,
	// Get count of all episode impressions
	getTotalImpressions: gql`
		query getTotalImpressions(
			$accountId: Int
			$podcastId: UUID
			$episodeId: UUID
			$shareSlug: String
			$themeId: UUID
			$startDate: Date!
			$endDate: Date!
			$isHosted: Boolean
		) {
			getTotalImpressions(
				accountId: $accountId
				podcastId: $podcastId
				episodeId: $episodeId
				shareSlug: $shareSlug
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
				isHosted: $isHosted
			)
		}
	`,
	// Get count of all episode and takeaway likes
	getTotalLikes: gql`
		query getTotalLikes(
			$accountId: Int
			$podcastId: UUID
			$episodeId: UUID
			$themeId: UUID
			$shareSlug: String
			$startDate: Date!
			$endDate: Date!
		) {
			getTotalLikes(
				accountId: $accountId
				podcastId: $podcastId
				episodeId: $episodeId
				themeId: $themeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	// Get count of all episode and takeaway shares
	getTotalShares: gql`
		query getTotalShares(
			$accountId: Int
			$podcastId: UUID
			$episodeId: UUID
			$themeId: UUID
			$shareSlug: String
			$startDate: Date!
			$endDate: Date!
		) {
			getTotalShares(
				accountId: $accountId
				podcastId: $podcastId
				episodeId: $episodeId
				themeId: $themeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,

	/* Show Level Metrics */
	// Get listen summary over time range for all episodes in a show
	getShowListenSummary: gql`
		query getShowListenSummary(
			$podcastId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getShowListenSummary(
				podcastId: $podcastId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				play_count
				download_count
				average_listen_time
				total_unique_views
				total_complete_views
				number_episodes
			}
		}
	`,
	// Get RSS metrics for a show by day - returns an array
	getDailyShowDownloadAndPlayCount: gql`
		query getDailyShowDownloadAndPlayCount(
			$podcastId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getDailyShowDownloadAndPlayCount(
				podcastId: $podcastId
				startDate: $startDate
				endDate: $endDate
			) {
				play_count
				download_count
				visit_count
				is_video
				date
				_id
			}
		}
	`,
	// Get (Casted website) listen metrics for a show by day - returns an array
	getDailyShowListens: gql`
		query getDailyShowListens(
			$podcastId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getDailyShowListens(
				podcastId: $podcastId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				average_listen_time
				total_unique_views
				total_complete_views
				number_episodes
				podcast_id
				date
				_id
			}
		}
	`,
	// Get listener breakdown for a show over a time range
	getShowNewAndLoyalListeners: gql`
		query getShowNewAndLoyalListeners(
			$podcastId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getShowNewAndLoyalListeners(
				podcastId: $podcastId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				loyal_listeners
				new_listeners
				episodes_per_listener
				bounce_rate
			}
		}
	`,
	// Top clips by clicks (will always be takeaways) for show or episode  - returns an array
	getShowTopClipsByClicks: gql`
		query getTopClipsByClicks(
			$podcastId: UUID
			$numberOfRows: Int
			$startDate: Date
			$endDate: Date
		) {
			getTopClipsByClicks(
				podcastId: $podcastId
				numberOfRows: $numberOfRows
				startDate: $startDate
				endDate: $endDate
			) {
				id
				episode_id
				name
				total_views
				episode_name
			}
		}
	`,
	// Top clips by bringing traffic to Casted for show or episode - returns an array
	getTopClipsByReferrals: gql`
		query getTopClipsByReferrals(
			$podcastId: UUID
			$numberOfRows: Int
			$startDate: Date
			$endDate: Date
		) {
			getTopClipsByReferrals(
				podcastId: $podcastId
				numberOfRows: $numberOfRows
				startDate: $startDate
				endDate: $endDate
			) {
				id
				episode_id
				name
				total_views
				average_listen_percentage
				episode_name
			}
		}
	`,
	// Top resources based on clicks for show  - returns an array
	getShowTopResources: gql`
		query getTopResources($podcastId: UUID, $startDate: Date, $endDate: Date) {
			getTopResources(
				podcastId: $podcastId
				startDate: $startDate
				endDate: $endDate
			) {
				id
				episode_id
				resource_name
				total_clicks
				unique_users
			}
		}
	`,
	// Avg Completion % for show
	getShowAverageCompletion: gql`
		query getShowAverageCompletion(
			$podcastId: UUID!
			$shareSlug: String
			$startDate: Date!
			$endDate: Date!
		) {
			getShowAverageCompletion(
				podcastId: $podcastId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,

	/* Episode Level Metrics */
	// Get the listen summary for an episode over the time range
	getEpisodeListenSummary: gql`
		query getEpisodeListenSummary(
			$episodeId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getEpisodeListenSummary(
				episodeId: $episodeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				play_count
				download_count
				average_listen_time
				total_unique_views
				total_complete_views
				episode_id
				date
				_id
			}
		}
	`,
	// Get RSS metrics for a show by day - returns an array
	getDailyEpisodeDownloadAndPlayCount: gql`
		query getDailyEpisodeDownloadAndPlayCount(
			$episodeId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getDailyEpisodeDownloadAndPlayCount(
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			) {
				play_count
				download_count
				visit_count
				is_video
				date
				_id
			}
		}
	`,
	// Get (Casted website) listen metrics for an episode by day - returns an array
	getDailyEpisodeListens: gql`
		query getDailyEpisodeListens(
			$episodeId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getDailyEpisodeListens(
				episodeId: $episodeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				average_listen_time
				total_unique_views
				total_complete_views
				episode_id
				date
				_id
			}
		}
	`,
	getEpisodeNewAndLoyalListeners: gql`
		query getEpisodeNewAndLoyalListeners(
			$podcastId: UUID!
			$episodeId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getEpisodeNewAndLoyalListeners(
				podcastId: $podcastId
				episodeId: $episodeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				loyal_listeners
				new_listeners
				episodes_per_listener
				bounce_rate
			}
		}
	`,
	// Top clips by clicks (will always be takeaways) for an episode  - returns an array
	getEpisodeTopClipsByClicks: gql`
		query getTopClipsByClicks(
			$podcastId: UUID
			$episodeId: UUID
			$numberOfRows: Int
			$startDate: Date
			$endDate: Date
		) {
			getTopClipsByClicks(
				podcastId: $podcastId
				episodeId: $episodeId
				numberOfRows: $numberOfRows
				startDate: $startDate
				endDate: $endDate
			) {
				id
				episode_id
				name
				total_views
			}
		}
	`,
	// Top clips by bringing traffic to Casted for an episode - returns an array
	getEpisodeTopClipsByReferrals: gql`
		query getTopClipsByReferrals(
			$podcastId: UUID
			$episodeId: UUID
			$numberOfRows: Int
			$startDate: Date
			$endDate: Date
		) {
			getTopClipsByReferrals(
				podcastId: $podcastId
				episodeId: $episodeId
				numberOfRows: $numberOfRows
				startDate: $startDate
				endDate: $endDate
			) {
				id
				episode_id
				name
				total_views
				average_listen_percentage
			}
		}
	`,
	// Top resources based on clicks for an episode - returns an array
	getEpisodeTopResources: gql`
		query getTopResources(
			$podcastId: UUID
			$episodeId: UUID
			$startDate: Date
			$endDate: Date
		) {
			getTopResources(
				podcastId: $podcastId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			) {
				id
				episode_id
				resource_name
				total_clicks
				unique_users
			}
		}
	`,
	// Avg Completion % for show
	getEpisodeAverageCompletion: gql`
		query getEpisodeAverageCompletion(
			$episodeId: UUID!
			$shareSlug: String
			$startDate: Date!
			$endDate: Date!
		) {
			getEpisodeAverageCompletion(
				episodeId: $episodeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,

	/* Clip Level Metrics */
	// Get listen metrics for a clip over a time range
	getClipListenSummary: gql`
		query getClipListenSummary(
			$clipId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getClipListenSummary(
				clipId: $clipId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				average_listen_time
				average_listen_percentage
				total_unique_views
				date
				_id
			}
		}
	`,
	// Get listen metrics for a clip by day - return an array
	getDailyClipListens: gql`
		query getDailyClipListens(
			$clipId: UUID!
			$shareSlug: String
			$startDate: Date
			$endDate: Date
		) {
			getDailyClipListens(
				clipId: $clipId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				is_video
				date
				_id
			}
		}
	`,
	// get traffic referrals for a clip over a time range
	// not used currently
	getClipListensByType: gql`
		query getClipListensByType(
			$clipId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getClipListensByType(
				clipId: $clipId
				startDate: $startDate
				endDate: $endDate
			) {
				audiogram_count
				embed_count
				link_count
			}
		}
	`,
	getShareExportData: gql`
		query getShareExportData(
			$podcastId: UUID!
			$episodeId: UUID!
			$shareSlug: String
			$startDate: Date!
			$endDate: Date!
		) {
			getShareExportData(
				podcastId: $podcastId
				episodeId: $episodeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,

	/* Theme Level Metrics */
	// Get listen summary over time range for all episodes in a theme
	getThemeListenSummary: gql`
		query getThemeListenSummary(
			$themeId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getThemeListenSummary(
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				play_count
				download_count
				average_listen_time
				total_unique_views
				total_complete_views
				number_episodes
			}
		}
	`,
	// Get listener breakdown for a theme over a time range
	getThemeNewAndLoyalListeners: gql`
		query getThemeNewAndLoyalListeners(
			$themeId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getThemeNewAndLoyalListeners(
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
			) {
				loyal_listeners
				new_listeners
				episodes_per_listener
			}
		}
	`,
	// Avg Completion % for theme
	getThemeAverageCompletion: gql`
		query getThemeAverageCompletion(
			$themeId: UUID!
			$startDate: Date!
			$endDate: Date!
		) {
			getThemeAverageCompletion(
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	// Get RSS metrics for a theme by day - returns an array
	getDailyThemeDownloadAndPlayCount: gql`
		query getDailyThemeDownloadAndPlayCount(
			$themeId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getDailyThemeDownloadAndPlayCount(
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
			) {
				play_count
				download_count
				visit_count
				is_video
				date
				_id
			}
		}
	`,
	// Get (Casted website) listen metrics for a theme by day - returns an array
	getDailyThemeListens: gql`
		query getDailyThemeListens(
			$themeId: UUID!
			$startDate: Date
			$endDate: Date
		) {
			getDailyThemeListens(
				themeId: $themeId
				startDate: $startDate
				endDate: $endDate
			) {
				total_views
				average_listen_time
				total_unique_views
				total_complete_views
				number_episodes
				account_id
				date
				_id
			}
		}
	`,
	// Get top keyword metrics for a theme
	getThemeTopKeywords: gql`
		query getThemeTopKeywords($themeId: UUID!, $numberOfRows: Int) {
			getThemeTopKeywords(themeId: $themeId, numberOfRows: $numberOfRows) {
				id
				keyword
				total_matches
			}
		}
	`,

	getThemesByAccountId: gql`
		query themes($accountId: Int!) {
			themes(accountId: $accountId) {
				__typename
				...ThemeParts
			}
		}
		${Fragments.ThemeParts}
	`,
	getThemeById: gql`
		query theme($themeId: UUID!) {
			theme(themeId: $themeId) {
				__typename
				...ThemeParts
			}
		}
		${Fragments.ThemeParts}
	`,
	getThemeMatches: gql`
		query themeMatches(
			$accountId: Int!
			$themeId: UUID!
			$episodeId: UUID
			$documentId: String
			$matchThreshold: Int!
		) {
			themeMatches(
				accountId: $accountId
				themeId: $themeId
				episodeId: $episodeId
				documentId: $documentId
				matchThreshold: $matchThreshold
			)
		}
	`,
	getTranscriptDocumentById: gql`
		query getTranscriptDocumentById($documentId: String!) {
			getTranscriptDocumentById(documentId: $documentId)
		}
	`,
	getRevealData: gql`
		query getRevealData(
			$accountId: Int
			$startDate: Date!
			$endDate: Date!
			$collectionId: UUID
			$episodeId: UUID
			$includeNoEngagements: Boolean
		) {
			getRevealData(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
				collectionId: $collectionId
				episodeId: $episodeId
				includeNoEngagements: $includeNoEngagements
			)
		}
	`,
	getSegmentGroupsCompanyIds: gql`
		query getSegmentGroupsCompanyIds(
			$accountId: Int
			$startDate: Date!
			$endDate: Date!
			$collectionId: UUID
			$episodeId: UUID
			$segmentGroupIds: [UUID]
		) {
			getSegmentGroupsCompanyIds(
				accountId: $accountId
				startDate: $startDate
				endDate: $endDate
				collectionId: $collectionId
				episodeId: $episodeId
				segmentGroupIds: $segmentGroupIds
			)
		}
	`,
	getCompanyData: gql`
		query getCompanyData($companyId: UUID) {
			getCompanyData(companyId: $companyId)
		}
	`,
	getSegmentData: gql`
		query getSegmentData(
			$accountId: Int
			$segmentId: UUID
			$startDate: Date!
			$endDate: Date!
			$collectionId: UUID
			$episodeId: UUID
			$dimensionIds: [UUID]
		) {
			getSegmentData(
				accountId: $accountId
				segmentId: $segmentId
				startDate: $startDate
				endDate: $endDate
				collectionId: $collectionId
				episodeId: $episodeId
				dimensionIds: $dimensionIds
			)
		}
	`,
	getExternalObjects: gql`
		query externalObjects(
			$accountId: Int!
			$objectTypeIds: [UUID]
			$parentId: UUID
			$order: String
		) {
			externalObjects(
				accountId: $accountId
				objectTypeIds: $objectTypeIds
				parentId: $parentId
				order: $order
			) {
				...ExternalObjectParts
			}
		}
		${Fragments.ExternalObjectParts}
	`,
	getExternalObjectById: gql`
		query externalObject($externalObjectId: UUID!) {
			externalObject(externalObjectId: $externalObjectId) {
				...ExternalObjectParts
			}
		}
		${Fragments.ExternalObjectParts}
	`,
	getExternalObjectTypes: gql`
		query externalObjectTypes(
			$includeObjectInstances: Boolean
			$accountId: Int
		) {
			externalObjectTypes(
				includeObjectInstances: $includeObjectInstances
				accountId: $accountId
			) {
				...ExternalObjectTypeParts
			}
		}
		${Fragments.ExternalObjectTypeParts}
	`,
	getThemeKeywordLimit: gql`
		query getThemeKeywordLimit {
			getThemeKeywordLimit
		}
	`,
	getFileUploadValidationSecret: gql`
		query getFileUploadValidationSecret {
			getFileUploadValidationSecret
		}
	`,
	getCollectionPermissionsForUserId: gql`
		query getCollectionPermissionsForUserId($accountId: Int!, $userId: Int) {
			getCollectionPermissionsForUserId(
				accountId: $accountId
				userId: $userId
			) {
				...CollectionPermissionParts
			}
		}
		${Fragments.CollectionPermissionParts}
	`,
	getCollectionPermissionByCollectionId: gql`
		query getCollectionPermissionByCollectionId(
			$accountId: Int!
			$collectionId: UUID!
			$userId: Int
		) {
			getCollectionPermissionByCollectionId(
				accountId: $accountId
				collectionId: $collectionId
				userId: $userId
			) {
				...CollectionPermissionParts
			}
		}
		${Fragments.CollectionPermissionParts}
	`,
	getMyCollectionPermissionsRoles: gql`
		query getMyCollectionPermissionsRoles($accountId: Int!, $userId: Int!) {
			getMyCollectionPermissionsRoles(accountId: $accountId, userId: $userId)
		}
	`,
	getPlaylistsByAccountId: gql`
		query playlists($accountId: Int!) {
			playlists(accountId: $accountId) {
				__typename
				...PlaylistParts
			}
		}
		${Fragments.PlaylistParts}
	`,
	getPlaylistById: gql`
		query playlist($accountId: Int!, $playlistId: UUID!) {
			playlist(accountId: $accountId, playlistId: $playlistId) {
				__typename
				...PlaylistParts
			}
		}
		${Fragments.PlaylistParts}
	`,
	getPlaylistContent: gql`
		query getPlaylistContent($accountId: Int!, $playlistId: UUID!) {
			getPlaylistContent(accountId: $accountId, playlistId: $playlistId) {
				id
				name
				thumbnail
			}
		}
	`,
	getCompanyProfileMetricsContentTable: gql`
		query getCompanyProfileMetricsContentTable(
			$accountId: Int!
			$companyId: UUID!
			$collectionId: UUID
			$episodeId: UUID
			$startDate: Date!
			$endDate: Date!
		) {
			getCompanyProfileMetricsContentTable(
				accountId: $accountId
				companyId: $companyId
				collectionId: $collectionId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getCompanyProfileMetricsChartAndCard: gql`
		query getCompanyProfileMetricsChartAndCard(
			$accountId: Int!
			$companyId: UUID!
			$collectionId: UUID
			$episodeId: UUID
			$startDate: Date!
			$endDate: Date!
		) {
			getCompanyProfileMetricsChartAndCard(
				accountId: $accountId
				companyId: $companyId
				collectionId: $collectionId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getCompanyProfileMetricsContentMix: gql`
		query getCompanyProfileMetricsContentMix(
			$accountId: Int!
			$companyId: UUID!
			$collectionId: UUID
			$episodeId: UUID
			$startDate: Date!
			$endDate: Date!
		) {
			getCompanyProfileMetricsContentMix(
				accountId: $accountId
				companyId: $companyId
				collectionId: $collectionId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getCompanyProfileMetricsThemeInsights: gql`
		query getCompanyProfileMetricsThemeInsights(
			$accountId: Int!
			$companyId: UUID!
			$collectionId: UUID
			$episodeId: UUID
			$startDate: Date!
			$endDate: Date!
		) {
			getCompanyProfileMetricsThemeInsights(
				accountId: $accountId
				companyId: $companyId
				collectionId: $collectionId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getSegmentsByAccountId: gql`
		query segments($accountId: Int!) {
			segments(accountId: $accountId) {
				__typename
				...SegmentParts
			}
		}
		${Fragments.SegmentParts}
	`,
	getPromotions: gql`
		query promotions($accountId: Int!, $collectionId: UUID, $itemId: UUID) {
			promotions(
				accountId: $accountId
				collectionId: $collectionId
				itemId: $itemId
			) {
				__typename
				...PromotionParts
			}
		}
		${Fragments.PromotionParts}
	`,
	getPromotionById: gql`
		query promotion($accountId: Int!, $promotionId: UUID!) {
			promotion(accountId: $accountId, promotionId: $promotionId) {
				__typename
				...PromotionParts
			}
		}
		${Fragments.PromotionParts}
	`,
	exportSegmentsMetrics: gql`
		query exportSegmentsMetrics(
			$accountId: Int!
			$collectionId: UUID
			$episodeId: UUID
			$startDate: Date!
			$endDate: Date!
		) {
			exportSegmentsMetrics(
				accountId: $accountId
				collectionId: $collectionId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	exportAudienceMetrics: gql`
		query exportAudienceMetrics(
			$accountId: Int!
			$collectionId: UUID
			$episodeId: UUID
			$shareSlug: String
			$startDate: Date!
			$endDate: Date!
		) {
			exportAudienceMetrics(
				accountId: $accountId
				collectionId: $collectionId
				episodeId: $episodeId
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getShares: gql`
		query shares(
			$accountId: Int!
			$collectionId: UUID
			$itemId: UUID
			$limit: Int
		) {
			shares(
				accountId: $accountId
				collectionId: $collectionId
				itemId: $itemId
				limit: $limit
			) {
				__typename
				...ShareParts
			}
		}
		${Fragments.ShareParts}
	`,
	getShare: gql`
		query share($shareSlug: String!) {
			share(shareSlug: $shareSlug) {
				__typename
				...ShareParts
			}
		}
		${Fragments.ShareParts}
	`,
	exportItemMetrics: gql`
		query exportItemMetrics(
			$accountId: Int!
			$episodeId: UUID!
			$startDate: Date!
			$endDate: Date!
		) {
			exportItemMetrics(
				accountId: $accountId
				episodeId: $episodeId
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	exportShowMetrics: gql`
		query exportPodcastMetrics(
			$accountId: Int!
			$podcastId: UUID!
			$exportType: String!
			$shareSlug: String
			$startDate: Date!
			$endDate: Date!
		) {
			exportPodcastMetrics(
				accountId: $accountId
				podcastId: $podcastId
				exportType: $exportType
				shareSlug: $shareSlug
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getYoutubeIntegrationChannels: gql`
		query getYoutubeIntegrationChannels(
			$podcastId: UUID!
		) {
			getYoutubeIntegrationChannels(
				podcastId: $podcastId
			)
		}
	`,
	getYoutubeIntegrationPlaylists: gql`
		query getYoutubeIntegrationPlaylists(
			$podcastId: UUID!
			$channelId: String!
		) {
			getYoutubeIntegrationPlaylists(
				podcastId: $podcastId
				channelId: $channelId
			)
		}
	`,
	exportAccountMetrics: gql`
		query exportAccountMetrics(
			$accountId: Int!
			$exportType: String!
			$startDate: Date!
			$endDate: Date!
		) {
			exportAccountMetrics(
				accountId: $accountId
				exportType: $exportType
				startDate: $startDate
				endDate: $endDate
			)
		}
	`,
	getPromotionFileExt: gql`
		query getPromotionFileExt($accountId: Int!, $promotionId: UUID!) {
			getPromotionFileExt(accountId: $accountId, promotionId: $promotionId)
		}
	`,
	getHeatmap: gql`
		query getHeatmap(
			$accountId: Int!
			$itemId: UUID!
			$startDate: Date!
			$endDate: Date!
			$clipId: UUID
			$byCompany: Boolean
		) {
			getHeatmap(
				accountId: $accountId
				itemId: $itemId
				startDate: $startDate
				endDate: $endDate
				clipId: $clipId
				byCompany: $byCompany
			)
		}
	`,
	getStandaloneClipStatus: gql`
		query standaloneClipStatus($clipId: UUID!) {
			standaloneClipStatus(clipId: $clipId)
		}
	`,
	getCampaignFields: gql`
		query getCampaignFields($accountId: Int!) {
			getCampaignFields(accountId: $accountId)
		}
	`,

	getSalesForceFieldsMappings: gql`
	query getSalesForceFieldsMappings($accountId: Int!) {
		getSalesForceFieldsMappings(accountId: $accountId)
	}
`,
	getAllCampaigns: gql`
		query getAllCampaigns($accountId: Int!) {
			getAllCampaigns(accountId: $accountId) {
			  Id
        Name
        StartDate
        EndDate
        Type
        Status
        Description
        ParentId}
		}
	`,
	getSaleforceIntegrationStatus: gql`
		query getSaleforceIntegrationStatus($accountId: Int!) {
			getSaleforceIntegrationStatus(accountId: $accountId)
		}
	`,

};
