import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { withRouter, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Spinner } from 'react-bootstrap';

import { parseQuery } from 'utils/';
import mutations from 'services/graphql/mutations';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';

const SalesforceCallback = (props) => {
	const params = parseQuery(props.location.search);
	const { code } = params;

	const [saveSalesforceOAuthToken] = useMutation(
		mutations.saveSalesforceOAuthIntegration,
		{
			variables: { code },
			onCompleted: () => {
				trackEvent('add-integration', { integrationName: 'salesforce' });
				Toast.success('Salesforce Integration Added!');
				setTimeout(()=>{
					localStorage.setItem("refreshSalesForceAuth", true);
					window.close();

				},3000)
			},
			onError: (err) => {
				Toast.error('Unable to add integration with Salesforce');
				Sentry.captureException(err);
			},
		}
	);

	useEffect(() => {
		saveSalesforceOAuthToken();
	}, []);

	return (
		<div>
        <Spinner animation="grow" variant="info" />
		</div>
	);
};

export default withRouter(SalesforceCallback);
