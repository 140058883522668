import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import Button from 'components/shared/button';
import Toast from 'services/toast';

import Select from 'react-select';
import './_campaignSelection.scss';

const CampaignSelection = ({ setShowSaleForceModal, onClose, podcastId, salesforceCampaignId,refetchPodcast }) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const { data, loading, error } = useQuery(queries.getAllCampaigns, {
    variables: { accountId: 123 },
    fetchPolicy: 'no-cache',
  });

  const campaigns = data?.getAllCampaigns || [];

  useEffect(()=>{
    const selectedCampaign = campaigns.find(campaign => campaign.Id === salesforceCampaignId);

    const _selectedCampaign = selectedCampaign
      ? {
          value: selectedCampaign.Id,
          label: selectedCampaign.Name
        }
      : null;
    
    setSelectedCampaign(_selectedCampaign);

  },[campaigns])


  const [addSalesForceCampaignIdInPodcast] = useMutation(
    mutations.addSalesForceCampaignIdInPodcast,
    {
      onCompleted: () => {
				Toast.success('Campaign selected successfully');
        refetchPodcast()
        setShowSaleForceModal('');

      },
      onError: (err) => {
        console.error(err);
				Toast.error('Campaign selection failed');

      },
    }
  );

  const handleSelect = () => {
    if (selectedCampaign) {
      const input = {
        podcastId: podcastId,
        salesforceCampaignId: selectedCampaign.value,
      };
      addSalesForceCampaignIdInPodcast({
        variables: { input },
      });
    }
  };

  const campaignOptions = campaigns.map((campaign) => ({
    value: campaign.Id,
    label: campaign.Name,
  }));

  return (
    <div className="campaign-modal-overlay">
      <div className="campaign-modal-container">
        <h2>Select a Salesforce Campaign</h2>
        <div className="campaign-select-container">
          <label htmlFor="campaignSearch">Choose a Campaign:</label>
          <Select
          id="campaignSearch"
          className="campaign-searchable-select"
          options={campaignOptions}
          onChange={setSelectedCampaign}
          value={selectedCampaign}
          placeholder="Search and select a campaign..."
          menuPortalTarget={document.body}
          isDisabled={salesforceCampaignId}
/>
        </div>
        <div className="modal-actions">
          <Button variant="danger" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            className="btn btn-primary"
            onClick={handleSelect}
            disabled={!selectedCampaign || salesforceCampaignId}
          >
            Select
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CampaignSelection;
