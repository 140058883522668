import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import './_saleForceCampaignSettings.scss';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import Button from 'components/shared/button';

const CampaignFieldsSelection = ({ onClose }) => {
  const [campaignMappings, setCampaignMappings] = useState({
    episodeTitleField: '',
    publishDateField: '',
    topicsField: '',
  });

  const [campaignMemberMappings, setCampaignMemberMappings] = useState({
    uniqueListenersField: '',
    // totalMinutesListenedField: '',
    // listeningSourceField: '',
    firstListenDateField: '',
    mostRecentListenDateField: '',
  });

  const { data, loading, error } = useQuery(queries.getCampaignFields, {
    variables: { accountId: 123 },
    fetchPolicy: 'no-cache',
  });

  const { data: fieldMappingsData, loading: fieldMappingsLoading, error: fieldMappingsError } = useQuery(queries.getSalesForceFieldsMappings, {
    variables: { accountId: 123 },
    fetchPolicy: 'no-cache',
  });

  const [saveSalesForceFieldsMappings] = useMutation(mutations.saveSalesForceFieldsMappings, {
    onCompleted: () => {
      onClose();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const handleCampaignFieldChange = (key, option) => {
    setCampaignMappings((prev) => ({ ...prev, [key]: option?.value || '' }));
  };

  const handleCampaignMemberFieldChange = (key, option) => {
    setCampaignMemberMappings((prev) => ({ ...prev, [key]: option?.value || '' }));
  };

  const handleSave = () => {
    const input = {
      episodeTitleField: campaignMappings.episodeTitleField,
      publishDateField: campaignMappings.publishDateField,
      topicsField: campaignMappings.topicsField,
      uniqueListenersField: campaignMemberMappings.uniqueListenersField,
      // totalMinutesListenedField: campaignMemberMappings.totalMinutesListenedField,
      // listeningSourceField: campaignMemberMappings.listeningSourceField,
      firstListenDateField: campaignMemberMappings.firstListenDateField,
      mostRecentListenDateField: campaignMemberMappings.mostRecentListenDateField,
    };

    saveSalesForceFieldsMappings({
      variables: { input },
    });
  };

  const campaignFields = data?.getCampaignFields?.campaignFields || [];
  const campaignMemberFields = data?.getCampaignFields?.campaignMemberFields || [];
  const savedCampaignFields = fieldMappingsData?.getSalesForceFieldsMappings || {};

  useEffect(()=>{
    if(savedCampaignFields){
      setCampaignMappings({
        episodeTitleField: savedCampaignFields['episodeTitleField'],
        publishDateField: savedCampaignFields['publishDateField'],
        topicsField: savedCampaignFields['topicsField']})
      setCampaignMemberMappings({
        uniqueListenersField: savedCampaignFields['uniqueListenersField'],
        // totalMinutesListenedField: savedCampaignFields['totalMinutesListenedField'],
        // listeningSourceField: savedCampaignFields['listeningSourceField'],
        firstListenDateField:  savedCampaignFields['firstListenDateField'],
        mostRecentListenDateField: savedCampaignFields['mostRecentListenDateField']})
    }
  },[fieldMappingsData])

  const formatOptions = (fields) =>
    fields.map((field) => ({
      value: field.name,
      label: field.label,
    }));

  const campaignOptions = formatOptions(campaignFields);
  const campaignMemberOptions = formatOptions(campaignMemberFields);

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Map Salesforce Fields</h2>

        <div className="mapping-section">
          <h3>Campaign Fields</h3>
          {Object.entries({
            episodeTitleField: 'Episode Title',
            publishDateField: 'Publish Date',
            topicsField: 'Topics',
          }).map(([key, label]) => (
            <div className="mapping-row" key={key}>
              <div className="field-name">{label}</div>
              <div className="field-dropdown">
                <Select
                  options={campaignOptions}
                  value={campaignOptions.find((option) => option.value === campaignMappings[key]) || null}
                  onChange={(option) => handleCampaignFieldChange(key, option)}
                  placeholder="Select a Salesforce Field"
                  isClearable
                  isDisabled={savedCampaignFields[key] ? true : false }
                />
              </div>
            </div>
          ))}
        </div>

        <div className="mapping-section">
          <h3>Campaign Member Fields</h3>
          {Object.entries({
            uniqueListenersField: 'Unique Listeners',
            // totalMinutesListenedField: 'Total Minutes Listened',
            // listeningSourceField: 'Listening Source',
            firstListenDateField: 'First Listen Date',
            mostRecentListenDateField: 'Most Recent Listen Date',
          }).map(([key, label]) => (
            <div className="mapping-row" key={key}>
              <div className="field-name">{label}</div>
              <div className="field-dropdown">
                <Select
                  options={campaignMemberOptions}
                  value={campaignMemberOptions.find((option) => option.value === campaignMemberMappings[key]) || null}
                  onChange={(option) => handleCampaignMemberFieldChange(key, option)}
                  placeholder="Select a Salesforce Field"
                  isClearable
                  isDisabled={savedCampaignFields[key] ? true : false }
                />
              </div>
            </div>
          ))}
        </div>

        {/* Modal Actions */}
        <div className="modal-actions">
            <Button variant="danger" onClick={onClose}>
            Cancel
          </Button>

          <Button
            variant="success"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CampaignFieldsSelection;
